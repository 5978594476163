import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import type { MenuProps } from "@mui/material";
import { Avatar, Menu, MenuItem, Stack, Box, ButtonBase } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { envConfig } from "utils/envConfig";
import { navigateToExternalSite } from "utils/navigationHelpers";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const UserMenuButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontFamily: theme.typography.body1.fontFamily,
  ":focus-visible": {
    outline: "auto",
  },
}));

export function UserInfo() {
  const { userInfo } = useUserContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (userInfo === undefined || userInfo.firstName === undefined) return <></>;
  return (
    <>
      <UserMenuButton
        id="profile-menu-button"
        onClick={handleClick}
        aria-controls={"profile-menu"}
        aria-expanded={open ? true : false}
        aria-haspopup="true"
        disableRipple
      >
        <Stack direction="row" alignItems="center">
          <Avatar src={userInfo?.profileImage} sx={{ width: 24, height: 24, cursor: "pointer" }}>
            {userInfo?.firstName.charAt(0)}
          </Avatar>{" "}
          <Box component="span" sx={{ cursor: "pointer", paddingLeft: 1, "&:hover": { color: "primary.main" } }}>
            {userInfo?.firstName} {userInfo?.lastName}
          </Box>
          <Box component="span" sx={{ color: "text.secondary", lineHeight: "0.8rem", cursor: "pointer" }}>
            <KeyboardArrowDown />
          </Box>
        </Stack>
      </UserMenuButton>
      <StyledMenu
        id="profile-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "profile-menu-button",
        }}
        disableAutoFocusItem
      >
        <MenuItem onClick={() => navigateToExternalSite(`${envConfig.OctopusComDomain}/profile`)} disableRipple={true}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => navigateToExternalSite(`/auth/logout`)} disableRipple={true}>
          Sign Out
        </MenuItem>
      </StyledMenu>
    </>
  );
}
