import React from "react";
import { Typography, Box, Stack, Divider, FormControlLabel, Alert, CircularProgress, Link } from "@mui/material";
import { fontSize } from "@octopusdeploy/design-system-tokens";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { formatMoney } from "utils/formatters/MoneyFormatter";
import type { OrderPreview } from "client/api/PurchaseApi";
import { LinkButton } from "components/LinkButton/LinkButton";
import { themeTokens } from "components/Theme/theme";
import { CustomErrorAlert, ErrorMessageWithTryAgainButtonOrContactSales } from "components/alert/CustomErrorAlert";
import { CheckoutCheckbox } from "areas/checkout/components/CheckoutCheckbox";
import type { OrderPreviewError } from "areas/purchasing/Checkout";
import { SummaryLine } from "./SummaryLine";
import { TaxSummary } from "./TaxSummary";

interface OrderPreviewSummaryProps {
  isTaxExempted: boolean;
  onToggleTaxExemption: () => void;
  orderPreview: OrderPreview;
  isLoadingOrderPreview: boolean;
  hasMinimumTaxInfo: boolean;
  errorReason: OrderPreviewError | null;
  showContactSalesDialog?: () => void;
}

const SubscriptionAgreement = ({ planName }: { planName: string }) => {
  return (
    <Typography sx={{ fontSize: "14px", color: "#586978", textAlign: "center" }}>
      By purchasing {planName} you accept our{" "}
      <Link href={ExternalUriMap.octopusComCustomerAgreementPath} target={"_blank"}>
        customer agreement
      </Link>
      .
    </Typography>
  );
};

export function OrderPreviewSummary(props: OrderPreviewSummaryProps) {
  const {
    onToggleTaxExemption,
    orderPreview,
    isTaxExempted,
    isLoadingOrderPreview,
    hasMinimumTaxInfo,
    errorReason,
    showContactSalesDialog,
  } = props;
  const useZuoraProfessionalCheckout = useFeatureFlag("ZuoraProfessionalCheckout");
  const discount = orderPreview.discountAmount;

  const planPriceDisplayString = formatMoney(orderPreview.totalAmountExcludingTax, { includeTrailingZeros: true });
  const discountDisplayString = formatMoney(discount, { includeTrailingZeros: true });
  const subTotalDisplayString = planPriceDisplayString;

  const totalDisplayString = formatMoney(orderPreview.totalAmount, {
    includeTrailingZeros: true,
  });

  return (
    <>
      {errorReason && errorReason.statusCode !== 400 && (
        <CustomErrorAlert message={<ErrorMessageWithTryAgainButtonOrContactSales retry={errorReason.retry} />} />
      )}
      <Box padding={3}>
        <Stack divider={<Divider sx={{ color: "#E6E8EA" }} />} spacing={2}>
          <Typography variant={"h6"} color={themeTokens.color.text.primary}>
            Order Summary
          </Typography>
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  variant={"body1"}
                  color={themeTokens.color.text.primary}
                  data-testid="order-summary-details-product-name"
                >
                  {orderPreview.productName}
                </Typography>
                {orderPreview.orderItems.length === 1 && (
                  <Typography fontSize={12} color={themeTokens.color.text.primary}>
                    {orderPreview.chargeDescription}
                  </Typography>
                )}
              </Box>
              <Typography
                variant={"body1"}
                color={themeTokens.color.text.primary}
                data-testid="order-summary-details-product-price"
              >
                {isLoadingOrderPreview && !hasMinimumTaxInfo ? <CircularProgress size={16} /> : planPriceDisplayString}
              </Typography>
            </Stack>
            {orderPreview.orderItems.length > 1 && (
              <Stack data-testid="order-summary-details-chargelines">
                {isLoadingOrderPreview && !hasMinimumTaxInfo ? (
                  <CircularProgress />
                ) : (
                  orderPreview.orderItems.map(
                    (item, i) =>
                      item.amountWithoutTax > 0 && (
                        <SummaryLine
                          sx={{ color: "#586978", fontSize: fontSize.xSmall, marginLeft: "16px" }}
                          label={`${item.quantity} ${item.chargeName}`}
                          value={formatMoney(item.amountWithoutTax, { includeTrailingZeros: true })}
                          key={i}
                        />
                      )
                  )
                )}
              </Stack>
            )}
            <SummaryLine label={"Billed yearly"} sx={{ color: "#586978" }} value={""} />
            {discount != 0 && (
              <SummaryLine label={"Annual Discount"} value={`${discountDisplayString}`} sx={{ color: "#00B065" }} />
            )}
          </Stack>
          <Stack spacing={1}>
            <SummaryLine
              label={"Subtotal"}
              value={
                isLoadingOrderPreview && !hasMinimumTaxInfo ? (
                  <CircularProgress size={16} />
                ) : (
                  `${subTotalDisplayString}`
                )
              }
            />
            <>
              <TaxSummary
                isFetchingTax={isLoadingOrderPreview}
                hasMinimumTaxInfo={hasMinimumTaxInfo}
                errorReason={errorReason}
                taxItems={orderPreview.taxItems}
              />
              <FormControlLabel
                control={
                  <CheckoutCheckbox id={"tax-exemption"} checked={isTaxExempted} onChange={onToggleTaxExemption} />
                }
                label={
                  <Typography sx={{ marginLeft: "16px" }} color={themeTokens.color.text.primary} variant={"body1"}>
                    I have a tax exemption certificate
                  </Typography>
                }
              />
              {isTaxExempted && (
                <Alert severity={"info"}>Our sales team will process your order to sight this certificate.</Alert>
              )}
            </>
          </Stack>
          <Box>
            <SummaryLine
              label={"Total"}
              value={
                !hasMinimumTaxInfo ? (
                  "$---.--"
                ) : isLoadingOrderPreview ? (
                  <CircularProgress size={16} />
                ) : errorReason ? (
                  "$---.--"
                ) : (
                  `${totalDisplayString}`
                )
              }
              sx={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}
              valueTestId="order-summary-total-price"
            />
          </Box>
        </Stack>
      </Box>
      <Box padding={3} sx={{ borderTop: "1px solid #E6E8EA" }}>
        <SubscriptionAgreement planName={orderPreview.productName} />
      </Box>
      {useZuoraProfessionalCheckout && (
        <Box padding={3} textAlign={"center"}>
          <LinkButton onClick={showContactSalesDialog}>Contact Sales</LinkButton>
        </Box>
      )}
    </>
  );
}
