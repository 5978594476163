import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { designSystemColors } from "@cpt/shared";
import { ExternalUriMap } from "utils/ExternalUriMap";
import type { ProductType } from "shared/ProductType";
import ControlCenterMigration from "../../resources/images/svgs/ccv1_to_ccv2_migration.svg";

type UpgradeSuccessBannerProps = {
  id: string;
  type: ProductType;
  onDismiss: () => void;
};

export function ControlCenterV2MigrationBanner(props: UpgradeSuccessBannerProps) {
  const { id, type, onDismiss } = props;

  const productLong = type === "CloudSubscription" ? "Cloud instance" : "Server license";
  const productShort = type === "CloudSubscription" ? "instances" : "licenses";

  return (
    <Paper sx={{ p: 2, pb: { xs: 2, lg: 0 }, position: "relative" }}>
      <IconButton
        aria-label="close"
        size="small"
        sx={{ position: "absolute", top: "16px", right: "16px" }}
        onClick={onDismiss}
      >
        <CloseIcon />
      </IconButton>
      <Grid container>
        <Grid item xs>
          <Typography variant="h6" fontWeight="bold" mb={2} mr={{ xs: 4, lg: 0 }}>
            <InfoIcon htmlColor={designSystemColors.blue["600"]} sx={{ verticalAlign: "sub", mr: 1 }} />
            Your {productLong} has been moved to Control Center V2
          </Typography>
          <Typography mb={2}>
            Control Center V2 is the future of Control Center. To learn more about managing your subscription in Control
            Center V2,{" "}
            <Link
              underline={"none"}
              fontWeight={500}
              href={ExternalUriMap.octopusComDocsManagingOctopusSubscriptionsPath}
              target="_blank"
            >
              read more
            </Link>
            .
          </Typography>
          <Typography>
            If you have any trials or {productShort} that haven't been renewed recently, they may be in{" "}
            <Link
              underline={"none"}
              fontWeight={500}
              href={ExternalUriMap.octopusComControlCenterV1RootPath}
              target="_self"
            >
              Control Center V1
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item xs="auto" ml={1} display={{ xs: "none", lg: "inherit" }}>
          <img src={ControlCenterMigration} alt={"Happy deployments!"} />
        </Grid>
      </Grid>
    </Paper>
  );
}
